<script lang="ts" setup>
import type { SearchProduct } from '~/types/api/search'

const { data: products } = useAsyncData(
  'featured-products',
  () => useNuxtApp().$api.product.getFeaturedProducts(),
  {
    default: () => [] as SearchProduct[],
    getCachedData: (key, nuxt): SearchProduct[] =>
      nuxt.payload.data[key] || nuxt.static.data[key],
  },
)
</script>

<template>
  <div class="flex gap-6 overflow-x-scroll md:grid md:grid-cols-3">
    <UIProductCard
      v-for="product in products"
      :key="product.id"
      :product="product"
      class="shrink-0 basis-[calc(100%-1.5rem)]"
    />
  </div>
</template>
