<script lang="ts" setup>
interface Model {
  name: string
  url: string
  price: number
  class: string
  text_class?: string
  image: string
  background?: string
}

const models: Model[] = [
  {
    name: 'iPhone 12 Series',
    url: '/category/iphone-12-series',
    price: 6_090_000,
    class: 'md:order-2',
    text_class: 'text-white',
    image: 'https://cdn.jagofon.com/mockdata/product-models/iphone-12.png',
    background:
      'https://cdn.jagofon.com/mockdata/product-models/iphone-12-background.png',
  },
  {
    name: 'iPhone 11 Series',
    url: '/category/iphone-11-series',
    price: 4_740_000,
    class: 'md:order-1',
    image: 'https://cdn.jagofon.com/mockdata/product-models/iphone-11.png',
  },
  {
    name: 'iPhone 13 Series',
    url: '/category/iphone-13-series',
    price: 8_160_000,
    class: 'md:order-3',
    image: 'https://cdn.jagofon.com/mockdata/product-models/iphone-13.jpg',
  },
  {
    name: 'iPhone X Series',
    url: '/category/iphone-x-series',
    price: 3_160_000,
    class: 'md:order-4',
    image: 'https://cdn.jagofon.com/mockdata/product-models/iphone-x.png',
  },
  {
    name: 'Galaxy S10',
    url: '/model/galaxy-s10',
    price: 4_100_000,
    class: 'md:order-5',
    image: 'https://cdn.jagofon.com/mockdata/product-models/galaxy-s10.jpg',
  },
]
</script>

<template>
  <div
    class="flex gap-6 overflow-x-scroll md:grid md:grid-cols-[3fr_4fr_3fr] md:grid-rows-2"
  >
    <NuxtLinkLocale
      v-for="(model, index) in models"
      :key="model.url"
      :to="model.url"
      class="shrink-0 basis-[calc(100%-1.5rem)] first:md:row-span-2"
      :class="model.class"
    >
      <UCard
        :ui="{
          base: 'h-full relative text-center overflow-hidden w-full',
          body: {
            base: 'flex flex-col items-center justify-space-between h-full',
          },
        }"
      >
        <NuxtPicture
          v-if="model.background"
          :src="model.background"
          :alt="model.name"
          sizes="300px md:600px"
          :img-attrs="{ class: 'absolute inset-0 size-full object-cover' }"
          :loading="index === 0 ? 'eager' : 'lazy'"
        />
        <NuxtPicture
          class="z-10 mb-6 mt-auto"
          :src="model.image"
          :alt="model.name"
          :img-attrs="{
            class:
              'size-36 shrink-0 object-contain' +
              (index === 0 ? ' md:size-80' : ''),
          }"
          :sizes="index === 0 ? '160px md:320px' : '160px'"
          quality="60"
          :modifiers="{ trim: '10' }"
          :loading="index === 0 ? 'eager' : 'lazy'"
        />
        <div
          class="z-10 mt-auto shrink-0 text-center"
          :class="model.text_class"
        >
          <p class="text-lg font-medium">{{ model.name }}</p>
          <p class="text-xs">
            {{ $t('global.starting-from') }} {{ $n(model.price, 'currency') }}
          </p>
        </div>
      </UCard>
    </NuxtLinkLocale>
  </div>
</template>
