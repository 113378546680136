<script setup lang="ts">
const { data: ongoingSale } = await usePromo().ongoingSale

// #region Check if voucher is present in URL
if (import.meta.client) {
  const voucher = useRoute().query.voucher as string | null

  if (voucher) {
    useNuxtApp()
      .$api.order.getVoucher(voucher, {
        amount: 1e7,
      })
      .then(async (response) => {
        useOrder().setVoucher({
          code: response.code,
          discount: response.value,
        })

        const modal = await import('~/components/ui/UIVoucherAppliedModal.vue')
        useModal().open(modal.default, {
          voucher: response,
        })
      })
  }
}
// #endregion

// #region SEO
const seo = await useSeo('home')
useCustomHead({
  title: seo.value.title,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: seo.value.description,
    },
  ],
})
// #endregion
</script>

<template>
  <div>
    <section class="pb-8 md:pb-12">
      <PagesHomeCarousel />
    </section>

    <section
      v-if="ongoingSale && ongoingSale.groups.length"
      class="px-6 pb-8 md:pb-12"
    >
      <div class="container">
        <LazyPagesHomeFlashSale />
      </div>
    </section>

    <section class="px-6 pb-8 md:pb-12">
      <div class="container">
        <h1 class="mb-3 text-center font-serif text-4xl capitalize">
          {{ $t('pages.home.featured-models') }}
        </h1>
        <PagesHomeFeaturedModels class="-mx-6 px-6" />
      </div>
    </section>

    <section class="px-6 pb-8 md:pb-12">
      <div class="container">
        <h2 class="mb-3 text-center font-serif text-4xl capitalize">
          {{ $t('pages.home.featured-products') }}
        </h2>
        <PagesHomeFeaturedProducts class="-mx-6 px-6" />
      </div>
    </section>

    <section class="px-6 pb-8 md:pb-12">
      <div class="container">
        <PagesHomeAboutUs />
      </div>
    </section>

    <section class="pb-8 md:pb-12">
      <PagesHomeTrustFactors />
    </section>

    <section class="px-6 pb-8 md:pb-12">
      <div class="container">
        <h2 class="mb-3 text-center font-serif text-4xl capitalize">
          {{ $t('pages.home.reviews') }}
        </h2>
        <UIReviews />
      </div>
    </section>

    <section class="px-6 pb-8 md:pb-12">
      <div class="container">
        <PagesHomeQualityAssurance />
      </div>
    </section>

    <section class="px-6 pb-8 md:pb-12">
      <div class="container">
        <h2 class="mb-3 font-serif text-4xl capitalize">
          {{ $t('global.frequently-asked-questions') }}
        </h2>
        <UIFaqs />
      </div>
    </section>
  </div>
</template>
