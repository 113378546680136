<script setup lang="ts">
import { NuxtLink, type UCarousel } from '#components'
import type { FetchError } from 'ofetch'
import type { Slide } from '~/repository/modules/slider'

const slides = await useNuxtApp().$api.slider.getSlides()

function applyVoucher(voucher: string) {
  useNuxtApp()
    .$api.order.getVoucher(voucher, {
      amount: 1e7,
    })
    .then(async (response) => {
      useOrder().setVoucher({
        code: response.code,
        discount: response.value,
      })

      const modal = await import('~/components/ui/UIVoucherAppliedModal.vue')
      useModal().open(modal.default, {
        voucher: response,
      })
    })
    .catch((e: FetchError) => {
      useSentry().captureException(e)
    })
}

const carousel = ref<InstanceType<typeof UCarousel> | null>(null)
let interval: NodeJS.Timeout | undefined
onMounted(() => {
  interval = setInterval(() => {
    if (!carousel.value) return

    if (carousel.value.page === carousel.value.pages) {
      return carousel.value.select(0)
    }

    return carousel.value.next()
  }, 5000)
})
onUnmounted(() => {
  clearInterval(interval)
})
</script>

<template>
  <UCarousel
    ref="carousel"
    arrows
    :items="slides"
    :ui="{
      container: 'h-[250px] lg:h-[300px] xl:h-[400px]',
      item: 'basis-full justify-center',
    }"
  >
    <template #default="{ item: slide, index }: { item: Slide; index: number }">
      <component
        :is="slide.url ? NuxtLink : 'div'"
        :to="!!slide.url ? slide.url : undefined"
        @click="slide.voucher && applyVoucher(slide.voucher)"
      >
        <picture>
          <source
            v-for="(image, i) in slide.images"
            :key="`${slide.name}-${i}`"
            :srcset="`${image.retina} 2x, ${image.normal} 1x`"
            :type="image.type"
            :media="image.media"
          />
          <img
            v-if="slide.originImage"
            :src="slide.originImage.retina"
            :alt="slide.name"
            :loading="index === 0 ? 'eager' : 'lazy'"
            class="mx-auto h-full object-contain"
          />
        </picture>
      </component>
    </template>
  </UCarousel>
</template>
